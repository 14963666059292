import React from 'react'

const ImgGenetic = () => {
    return (
        <svg viewBox="0 0 185 365" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M-1.28537e-05 260.196L-1.29054e-05 261.242L16.0564 261.242L159.56 261.242C159.811 264.259 159.936 267.398 159.936 270.61L159.936 301.972L-1.49175e-05 301.972L-1.61699e-05 327.325L159.936 327.325L159.936 364.43L184.999 364.43L184.999 260.318C184.999 204.356 149.951 170.901 92.4996 170.901C57.7276 170.901 35.6501 156.327 28.0485 128.565L182.516 128.565C184.171 120.998 184.999 112.847 184.999 104.161L184.999 103.237L25.4142 103.237C25.1884 99.4652 25.063 95.5966 25.063 91.6306L25.063 62.4577L184.999 62.4578L184.999 37.1048L25.063 37.1048L25.063 -6.18597e-06L0 -7.15534e-06L-5.14919e-06 104.234C-7.91369e-06 160.195 35.048 193.529 92.4996 193.529C127.222 193.529 149.274 208.128 156.926 235.865L2.45865 235.865C0.82793 243.432 -1.24258e-05 251.534 -1.28537e-05 260.196Z" fill="#D22673" />
            <path d="M0.000597498 260.196L0.000592349 364.43L25.0636 364.43L25.0636 260.196C25.0636 216.643 48.6965 193.553 92.5003 193.553C149.952 193.553 185 160.098 185 104.137L185 0L159.937 -9.69377e-07L159.937 104.112C159.937 147.665 136.304 170.877 92.5003 170.877C35.0236 170.901 0.000600262 204.235 0.000597498 260.196Z" fill="#A7E1FF" />
        </svg>
    )
}

export default ImgGenetic
