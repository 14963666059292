import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from 'components/layout'
import SEO from 'components/seo'
import AboutPage from 'components/about-page'

const About = ({ data, location, pageContext }) => {
  const wordpressPage = data.wordpressWpGatsbyPage.acf

  return (
    <Layout location={location} pageContext={pageContext}>
      <SEO
        title={wordpressPage.title}
        description={wordpressPage.subtitle}
        canonical={wordpressPage.canonical}
        pathName={location.pathname}
      />
      <AboutPage data={wordpressPage} />
    </Layout>
  )
}

About.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

export default About

export const pageQuery = graphql`
  query($slug: String!) {
    wordpressWpGatsbyPage(slug: { eq: $slug }) {
      acf {
        canonical
        title
        subtitle
        subtitle
        demonstrative_text
        title_banner_1
        description_banner_1
        title_areas
        description_areas
        title_banner_2
        description_banner_2
        exams_number
        exams_title
        units_number
        units_title
        countries_present_qtd
        countries_present_title
        partners_number
        partners_title
        self_collection_exams_qtd
        self_collection_exams_title
        carousel_allExams_btn_desk
        carousel_allExams_btn_mobile
        show_quantitative_section
        image_1 {
          alt_text
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        image_2 {
          alt_text
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        image_3 {
          alt_text
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        image_4 {
          alt_text
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        img_banner_1_desk {
          alt_text
          localFile {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        img_banner_1_mobile {
          alt_text
          localFile {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        img_banner_2_desk {
          alt_text
          localFile {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        img_banner_2_mobile {
          alt_text
          localFile {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
