import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import BrandNumbers from './BrandNumbers'
import * as S from './styled'
import ImgBannerAbout from './ImgBannerAbout'
import ImgSectionAbout from './ImgSectionAbout'
import ImgBannerTwo from './ImgBannerTwo'
import Img from 'gatsby-image'
import ImgGenetic from '../BannerInstitutional/imgGenetic'
import { ArrowRight, ArrowLeft, ChevronRight } from '@dasa-health/alma-icons'
import SwiperCore, { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css'
import 'swiper/swiper-bundle.min.css'
import 'swiper/components/pagination/pagination.min.css'
import 'swiper/components/navigation/navigation.min.css'

SwiperCore.use([Pagination, Navigation]);

const AboutPage = ({ data }) => {
  const { areas } = useStaticQuery(graphql`
    query {
      areas: allWordpressWpExpertiseAreaPage(sort: {fields: acf___ordem_desktop, order: ASC}) {
        edges {
          node {
            acf {
              description_area
              link_atuacao
              link_exames
              nome
              icone {
                alt_text
                localFile {
                  childImageSharp {
                      fluid(quality: 100) {
                          ...GatsbyImageSharpFluid_withWebp
                      }
                  }
                }
              }
              ordem_desktop
            }
          }
        }
      }
    }
  `)

  const wordpressWpGatsbyPage = data
  const AreaItens = areas.edges

  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)

  return (
    <>
      <S.AboutAndBackgroundContainer>
        <S.AboutContainer>
          <S.SubtitleContainer>
            <S.AboutSubtitle>
              <h1 dangerouslySetInnerHTML={{ __html: wordpressWpGatsbyPage.subtitle }} />
            </S.AboutSubtitle>

            <S.AboutSubtitleDescription>
              <p dangerouslySetInnerHTML={{ __html: wordpressWpGatsbyPage.demonstrative_text }} />
            </S.AboutSubtitleDescription>

          </S.SubtitleContainer>
        </S.AboutContainer>

        <S.ImagePeoples>
          <ImgBannerAbout />
        </S.ImagePeoples>
      </S.AboutAndBackgroundContainer>      

      <S.SectionContainerFullWidth>
        <S.SectionContainer>
          <S.ImageContainer>
            {wordpressWpGatsbyPage.img_banner_1_desk
            ?
              <S.BannerOneDesk>
                <Img 
                  fluid={wordpressWpGatsbyPage.img_banner_1_desk.localFile.childImageSharp.fluid}
                  alt={wordpressWpGatsbyPage.img_banner_1_desk.alt_text}
                />
              </S.BannerOneDesk>
            :
              <ImgSectionAbout/>
            }

            {wordpressWpGatsbyPage.img_banner_1_mobile
              ?
                <S.BannerOneMobile>
                  <Img 
                    fluid={wordpressWpGatsbyPage.img_banner_1_mobile.localFile.childImageSharp.fluid}
                    alt={wordpressWpGatsbyPage.img_banner_1_mobile.alt_text}
                  />
                </S.BannerOneMobile>
              :
                <ImgSectionAbout/>
            }
          </S.ImageContainer>

          <S.SectionAbout>
            <S.SectionContent>
              <S.AboutDescriptionContent>
                <S.ImageTitle>
                  <h2 dangerouslySetInnerHTML={{ __html: wordpressWpGatsbyPage.title_banner_1 }} />
                </S.ImageTitle>

                <S.ImageDescription>
                  <div dangerouslySetInnerHTML={{ __html: wordpressWpGatsbyPage.description_banner_1 }} />
                </S.ImageDescription>
              </S.AboutDescriptionContent>
            </S.SectionContent>
          </S.SectionAbout>
        </S.SectionContainer>
      </S.SectionContainerFullWidth>

      <S.AreasContainerFullWidth>
        <S.AreasContainer>
          <S.AreasContent>
            <S.ImgGenetic>
              <ImgGenetic />
            </S.ImgGenetic>

            <S.AreasTitle>
              {wordpressWpGatsbyPage.title_areas} <br></br>
            </S.AreasTitle>

            <S.AreasDescription>
              {wordpressWpGatsbyPage.description_areas}
            </S.AreasDescription>
          </S.AreasContent>

          <S.CarouselAreasContainer>
            
            <Swiper
              spaceBetween={0}
              slidesPerView={"auto"}
              grabCursor={true}
              modules={[Pagination, Navigation]}
              navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
              }}
              onBeforeInit={(swiper) => {
                swiper.params.navigation.prevEl = navigationPrevRef.current;
                swiper.params.navigation.nextEl = navigationNextRef.current;
              }}
              pagination={{
                clickable: true
              }}
            >
              {AreaItens.map((item) => (
                <SwiperSlide key={item.toString()}>
                 <S.AreaItem>
                   <S.AreaIcon>
                      <Img 
                          fluid={item.node.acf.icone.localFile.childImageSharp.fluid}
                          alt={item.node.acf.icone.alt_text}
                      />
                   </S.AreaIcon>

                   <S.AreaTitleLink to={item.node.acf.link_atuacao}>
                     <S.AreaTitle>
                       {item.node.acf.nome}
                     </S.AreaTitle>
                   </S.AreaTitleLink>

                   <S.AreaLinks>
                     <S.AreaText>
                       {item.node.acf.description_area}
                     </S.AreaText>

                     <S.AreaAllExams to={item.node.acf.link_exames}>
                       {wordpressWpGatsbyPage.carousel_allExams_btn_desk}

                       <ChevronRight />
                     </S.AreaAllExams>

                     <S.AreaAllExamsMobile to={item.node.acf.link_exames}>
                       {wordpressWpGatsbyPage.carousel_allExams_btn_mobile}
                     </S.AreaAllExamsMobile>
                   </S.AreaLinks>
                 </S.AreaItem>
               </SwiperSlide>
              ))}

                <SwiperSlide
                  className="swiper-filler"
                >
                </SwiperSlide>

                <S.NavigationContainer>
                  <S.BtnNavigationPrev ref={navigationPrevRef}>
                    <ArrowLeft />
                  </S.BtnNavigationPrev>

                  <S.BtnNavigationNext ref={navigationNextRef}>
                    <ArrowRight />
                  </S.BtnNavigationNext>
              </S.NavigationContainer>
            </Swiper>
          </S.CarouselAreasContainer>
        </S.AreasContainer>
      </S.AreasContainerFullWidth>

      <S.BannerTwoContainerFullWidth>
        <S.BannerTwoContainer>
          <S.BannerTwoContent>
            <S.BannerTwoTitle>
              {wordpressWpGatsbyPage.title_banner_2}
            </S.BannerTwoTitle>

            <S.BannerTwoDescription>
              {wordpressWpGatsbyPage.description_banner_2}
            </S.BannerTwoDescription>
          </S.BannerTwoContent>

          <S.BannerTwoImg>
            {wordpressWpGatsbyPage.img_banner_2_desk
            ?
              <S.BannerTwoDesk>
                <Img 
                  fluid={wordpressWpGatsbyPage.img_banner_2_desk.localFile.childImageSharp.fluid}
                  alt={wordpressWpGatsbyPage.img_banner_2_desk.alt_text}
                />
              </S.BannerTwoDesk>
            :
              <ImgBannerTwo />
            }

            {wordpressWpGatsbyPage.img_banner_2_mobile
              ?
                <S.BannerTwoMobile>
                  <Img 
                    fluid={wordpressWpGatsbyPage.img_banner_2_mobile.localFile.childImageSharp.fluid}
                    alt={wordpressWpGatsbyPage.img_banner_2_mobile.alt_text}
                  />
                </S.BannerTwoMobile>
              :
                <ImgBannerTwo />
            }
          </S.BannerTwoImg>
        </S.BannerTwoContainer>
      </S.BannerTwoContainerFullWidth>

      {wordpressWpGatsbyPage.show_quantitative_section && (
        <S.BrandNumbersList>
          <BrandNumbers
            field="countries"
            stateNumber={wordpressWpGatsbyPage.countries_present_qtd}
            stateTitle={wordpressWpGatsbyPage.countries_present_title}
          />
          <BrandNumbers
            field="exams"
            stateNumber={wordpressWpGatsbyPage.exams_number}
            stateTitle={wordpressWpGatsbyPage.exams_title}
          />
          <BrandNumbers
            field="units"
            stateNumber={wordpressWpGatsbyPage.units_number}
            stateTitle={wordpressWpGatsbyPage.units_title}
          />
          <BrandNumbers
            field="partners"
            stateNumber={wordpressWpGatsbyPage.partners_number}
            stateTitle={wordpressWpGatsbyPage.partners_title}
          />
          <BrandNumbers
            field="self_collection_exams"
            stateNumber={wordpressWpGatsbyPage.self_collection_exams_qtd}
            stateTitle={wordpressWpGatsbyPage.self_collection_exams_title}
          />
        </S.BrandNumbersList>
      )}

      <S.ImagesGallery>
        <S.ImageOne>
          <Img 
            fluid={wordpressWpGatsbyPage.image_1.localFile.childImageSharp.fluid}
            alt={wordpressWpGatsbyPage.image_1.alt_text}
          />
        </S.ImageOne>

        <S.ImageTwo>
          <Img
            fluid={wordpressWpGatsbyPage.image_2.localFile.childImageSharp.fluid}
            alt={wordpressWpGatsbyPage.image_2.alt_text}
            />
        </S.ImageTwo>

        <S.ImageThree>
          <Img
            fluid={wordpressWpGatsbyPage.image_3.localFile.childImageSharp.fluid}
            alt={wordpressWpGatsbyPage.image_3.alt_text}
            />
        </S.ImageThree>

        <S.ImageFour>
          <Img 
            fluid={wordpressWpGatsbyPage.image_4.localFile.childImageSharp.fluid}
            alt={wordpressWpGatsbyPage.image_4.alt_text}
          />
        </S.ImageFour>
      </S.ImagesGallery>

      <S.BlueBackground />
    </>
  )
}
export default AboutPage
