import styled from 'styled-components'
import media from 'styled-media-query'
import Img from 'gatsby-image'
import { ContainerDasa } from '../shared/container'
import { Subtitle } from '../shared/text-styled'
import { Link } from 'gatsby'
import { CarouselContainer } from '../carousel/styled'
import { alvTokens } from '@dasa-health/alma-tokens'

export const AboutAndBackgroundContainer = styled(ContainerDasa)`
  max-width: 1366px;
  position: relative;
  margin-top: -3.2rem;
  padding-top: 5.6rem;

  ${media.lessThan('large')`
    padding: 0 1.6rem 0;
    margin: 0 0 4.8rem;
  `}
`

export const AboutContainer = styled.div`
  margin: 0 auto;
  max-width: 1092px;
`

export const SectionContainer = styled.div`
  margin: 0 auto;
  max-width: 1366px;
  position: relative;

  ${media.lessThan('large')`
    display: flex;
    flex-direction: column;
  `}
`

export const SectionContainerFullWidth = styled(ContainerDasa)`
  max-width: 100%;
  min-height: 66.8rem;
  padding: 10.5rem 0 0;
  background-color: ${props => props.theme.colors.brand.primary[10]};

  ${media.lessThan('large')`
    padding: 0;
    margin: 0;
  `}
`

export const SubtitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 17.5rem;

  ${media.lessThan('large')`
    width:100%;
    font-size: 20px;
    margin-bottom: 0;
  `}

  p {
    font-size: 16px;
    font-weight: 400;
    color: ${alvTokens.ColorPaletteGray30};
    line-height: 150%;
    margin: 0;
    max-width: 548px;

    ${media.between('medium', 'large')`
      max-width: 428px;  
    `}

    ${media.lessThan('medium')`
      width:100%;
      font-size: 14px;
      line-height: 170%;
      max-width: 100%;
    `}
  }
`

export const AboutSubtitle = styled.div`
  max-width: 548px;
  
  h1 {
    color: ${props => props.theme.colors.brand.primary[10]};
    font-size: 64px;
    font-weight: 700;
    line-height: 125%;
    margin: 0 0 2.4rem 0;
    letter-spacing: -1px;
  }

  span {
    color: var(--pink);
  }

  ${media.between('medium', 'large')`
    max-width: 428px;  
  `}

  ${media.lessThan('medium')`
    width: 100%;
    max-width: 94%;
    line-height: 120%;
    font-size: 40px;
    margin: 0 0 1.6rem 0;
  `}
`

export const AboutSubtitleDescription = styled.div`
  max-width: 548px;

  ${media.between('medium', 'large')`
    max-width: 428px;  
  `}

  p {
    font-size: 16px;
    font-weight: 400;
    color: ${alvTokens.ColorPaletteGray30};
    line-height: 150%;
    margin: 0;

    ${media.lessThan('medium')`
      font-size: 14px;
      line-height: 170%;
    `}
  }

  ${media.lessThan('medium')`
    width:100%;
    max-width: 100%;
  `}
`

export const BrandNumbersList = styled.ul`
  padding: 0;
  text-align: center;
  margin: 10rem auto;
  max-width: 1366px;

  ${media.lessThan('medium')`
    margin: 5.6rem 0;
  `}
`

export const BrandNumbersItems = styled.li`
  list-style-type: none;
  margin-bottom: 8.8rem;

  ${media.lessThan('medium')`
    width:100%;
    margin-bottom: 3.2rem;
  `}
`

export const BrandNumbersMain = styled.h4`
  font-size: 64px;
  margin: 0 .8rem 0;
  color: var(--pink);
  width: 70%;

  ${media.lessThan('medium')`
    font-size: 32px;
    max-width: 85%;
  `}
`

export const BrandNumbersDescription = styled.p`
  max-width: 76.2rem;
  margin: 0 auto;
  font-size: 16px;
  color: ${alvTokens.ColorPaletteGray30};
  font-weight: 400;
  line-height: 150%;

  ${media.lessThan('medium')`
    font-size: 16px;
    max-width: 90%;
  `}
`

export const BrandNumbersTitle = styled.span`
  font-size: 64px;
  color: ${props => props.theme.colors.brand.primary[10]};
  font-weight: 700;
  line-height: 125%;
  margin: 0 0 0 .8rem;

  ${media.lessThan('medium')`
    font-size: 32px;
    margin: 0 0 0 .6rem;
  `}
`

export const BrandNumbersContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 1.6rem;

  ${media.lessThan('medium')`
    align-items: flex-start;
    margin: 0 0 .8rem;
  `}
`

export const SectionAbout = styled.section`
  display: flex;
  margin-left: calc(49.2% + 12.7rem);
  padding-top: 11.2rem;

  ${media.lessThan('large')`
    margin: 0;
    padding: 3.2rem 1.6rem;
    justify-content: center;
  `}
`

export const Image = styled(Img)`
  width: 85%;
  margin: 0 auto;

  ${media.lessThan('medium')`
    width:100%;
    margin: 0 0 3.2rem 0;
  `}
`

export const ImageContainer = styled.div`
  display: flex;
  max-width: 65.6rem;
  width: 100%;
  position: absolute;
  top: -19.9rem;
  left: 0;

  ${media.lessThan('large')`
    width: 100%;
    margin: 0 auto;
    position: inherit;
    top: 0;
  `}
`

export const BannerOneDesk = styled.div`
  width: 65.6rem;
  height: 68.1rem;

  .gatsby-image-wrapper {
    height: 100%;
  }

  ${media.lessThan('medium')`
    display: none;
  `};
`

export const BannerOneMobile = styled.div`
  display: none;

  ${media.lessThan('medium')`
    display: block;
    min-width: 100%;
  `};
`

export const BannerTwoDesk = styled(BannerOneDesk)`
  min-width: 67.1rem;
`

export const BannerTwoMobile = styled(BannerOneMobile)``

export const SectionContent = styled.div``

export const AboutDescriptionContent = styled.div`
  max-width: 42.2rem;
`

export const ImageTitle = styled.div`
  h2 {
      font-size: 40px;
      color: ${alvTokens.ColorPaletteWhite};
      line-height: 120%;
      margin: 0 0 2.4rem 0;
      letter-spacing: -1px;

      ${media.lessThan('large')`
        font-size: 24px;
      `}
  }
`

export const ImageDescription = styled(Subtitle)`
  margin: 0;
  color: ${alvTokens.ColorNeutral20};
  line-height: 150%;
  font-size: 16px;

  ${media.lessThan('large')`
    width:100%;
  `}

  p {
    margin: 0 0 2.4rem;

    &:last-child {
      margin: 0;
    }
  }
`

export const CountAuxiliaryText = styled.span`
  font-size: 64px;
  margin: 0 0 0 -1.2rem;

  ${media.lessThan('medium')`
    font-size: 32px;
    margin: 0 0 0 -0.4rem;
  `};
`

export const ImagePeoples = styled.div`
    position: absolute;
    top: -6.4rem;
    right: 1.5rem;
    width: 695px;
    height: 743.1px;

    ${media.between('medium', 'large')`
      top: 1rem;
      width: 41.122%;
    `}

    ${media.lessThan('medium')`
      display: none;
    `};
`;

export const AreasContainerFullWidth = styled(ContainerDasa)`
  max-width: 100%;
  min-height: 87.1rem;
  background: ${alvTokens.ColorNeutral10};
  
  ${media.lessThan('medium')`
    min-height: 39.2rem;
  `};
`

export const AreasContainer = styled.div`
`

export const ImgGenetic = styled.div`
  max-width: 21.41rem;
  width: 100%;
  position: absolute;
  top: -12.5rem;
  right: 9.088rem;

  ${media.lessThan('large')`
      display: none;
  `};
`

export const AreasContent = styled.div`
  max-width: 1366px;
  margin: 0 auto;
  padding: 10.3rem 0 4.8rem 13.7rem;
  position: relative;
  background: ${alvTokens.ColorNeutral10};

  ${media.lessThan('medium')`
    padding: 3.2rem 0 2.4rem;
  `};
`

export const AreasTitle = styled.h2`
  font-weight: 700;
  font-size: 40px;
  line-height: 120%;
  letter-spacing: -1px;
  color: ${props => props.theme.colors.brand.primary[10]};
  margin: 0 0 2.4rem;
  max-width: 76.2rem;

  ${media.lessThan('medium')`
    font-size: 24px;
    font-weight: 700;
  `};
`

export const AreasDescription = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: ${alvTokens.ColorPaletteGray30};
  max-width: 76.2rem;

  ${media.lessThan('medium')`
    color: ${alvTokens.ColorNeutralInverse20};
    margin: 0;
  `};
`

export const CarouselAreasContainer = styled(CarouselContainer)`
  max-width: 1366px;
  min-height: 40rem;
  margin: 0 auto;
  padding-left: 13.6rem;
  background: ${alvTokens.ColorNeutral10};

  .swiper-container {
    height: 39.8rem;
    margin-left: 0;

    ${media.lessThan('medium')`
        height: 38.4rem;
        min-height: 35rem;
        margin-bottom: 4rem;
    `};
    
    .swiper-slide {
      max-width: 31.1rem;
      margin-right: 2.4rem;
      
      ${media.lessThan('medium')`
        max-width: 25rem;
      `};
    }

    .swiper-pagination {
      z-index: 9;

      &.swiper-pagination-bullets {
        bottom: 3.2rem;
        
        ${media.lessThan('medium')`
          bottom: 0;
        `};
      }
    }

    .swiper-wrapper {
      min-height: 380px;

      ${media.lessThan('medium')`
        padding: 0;
      `};
    }
  }

  ${media.lessThan('medium')`
    padding: 0;
    min-height: 39rem;
  `};
`

export const NavigationContainer = styled.div`
  display: flex;
  margin-left: 21.2rem;
  position: absolute;
  bottom: 2.2rem;
  z-index: 11;

  ${media.lessThan('medium')`
    display: none;
  `};
`

export const BtnNavigationPrev = styled.button`
    width: 3.2rem;
    height: 3.2rem;
    border: 0;
    padding: 0;
    color: ${props => props.theme.colors.palette.black};
    background-color: transparent;
    cursor: pointer;
    z-index: 11;

    &:disabled {
      color: #C8C8C8;
    }

    svg {
      font-size: 3.2rem;
    }
`

export const BtnNavigationNext = styled.button`
    width: 3.2rem;
    height: 3.2rem;
    border: 0;
    padding: 0;
    margin-left: 2.3rem;
    color: ${props => props.theme.colors.palette.black};
    background-color: transparent;
    cursor: pointer;
    z-index: 11;

    &:disabled {
      color: #C8C8C8;
    }

    svg {
      font-size: 3.2rem;
    }
`

export const AreasItems = styled.div`
  display: flex;
`

export const AreaItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${alvTokens.ColorPaletteWhite};
  border: 1px solid rgba(22, 22, 22, 0.24);
  border-radius: 8px;
  padding: 2.4rem;
  height: 27rem;

  ${media.lessThan('medium')`
    display: block;
    height: 30.2rem;
  `};
`

export const AreaTitleLink = styled(Link)`
  text-decoration: none;
`

export const AreaTitle = styled.h3`
  font-weight: 700;
  line-height: 150%;
  font-size: 16px;
  color: ${props => props.theme.colors.brand.primary[10]};
  margin-bottom: 1.6rem;
  
  ${media.lessThan('medium')`
    font-size: 20px;
    margin: 1.6rem 0;
    min-height: 6rem; 
  `};
`

export const AreaLinks = styled.div`
  display: flex;
  flex-direction: column;
`

export const AreaLink = styled(Link)`
  font-weight: 400;
  font-size: 14px;
  text-decoration: underline;
  line-height: 170%;
  color: ${alvTokens.ColorPaletteGray30};
  margin-bottom: 1.6rem;
  min-width: 26.3rem;
  min-height: 2.4rem;

  &:last-child {
    margin-bottom: 0;
  }

  ${media.lessThan('medium')`
    width: 20.2rem;
    min-width: auto;
    margin-bottom: .8rem;
  `};
`

export const AreaText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 133%;
  color: ${alvTokens.ColorPaletteGray30};
  margin: 0 0 1.6rem;
  min-height: 9.3rem;
  
  ${media.lessThan('medium')`
    font-size: 12px;
    min-height: 9.6rem;
  `};
`

export const AreaAllExams = styled(Link)`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 115%;
  color: ${props => props.theme.colors.brand.primary[10]};
  text-decoration: none;

  ${media.lessThan('medium')`
    display: none;
  `};
`

export const AreaAllExamsMobile = styled(Link)`
    display: flex;
    justify-content: center;
    background-color: var(--pink);
    color: ${alvTokens.ColorPaletteWhite};
    border-radius: 4px;
    max-width: 7.8rem;
    padding: .8rem;
    font-weight: 700;
    font-size: 14px;
    line-height: 115%;
    border: 0;
    text-decoration: none;

    ${media.greaterThan('medium')`
      display: none;
    `}
`

export const AreaIcon = styled.div`
  width: 6.4rem;
  height: 6.4rem;
`

export const BannerTwoContainerFullWidth = styled(ContainerDasa)`
  max-width: 100%;
  background-color: var(--pink);

  ${media.lessThan('large')`
    display: flex;
    flex-direction: column-reverse;
    padding: 0;
  `}
`

export const BannerTwoContainer = styled.div`
  max-width: 1366px;
  margin: 0 auto;
  position: relative;

  ${media.lessThan('large')`
    display: flex;
    flex-direction: column-reverse;
  `}
`

export const BannerTwoTitle = styled.h2`
  font-weight: 700;
  font-size: 40px;
  line-height: 120%;
  letter-spacing: -1px;
  color: ${alvTokens.ColorPaletteWhite};
  margin: 0 0 2.4rem;
  max-width: 84%;
  
  ${media.lessThan('large')`
    max-width: 71%;
    font-size: 24px;
    line-height: 133%;
    margin: 0 0 1rem;
  `}
`

export const BannerTwoDescription = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: ${alvTokens.ColorNeutral20};
  margin: 0;
`

export const BannerTwoContent = styled.div`
  max-width: 42.2rem;
  padding: 19rem 0 16.9rem 13.7rem;

  ${media.lessThan('large')`
    padding: 5.6rem 1.6rem;
    margin: 0 auto;
  `}
`

export const BannerTwoImgContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`

export const BannerTwoImg = styled.div`
  position: absolute;
  right: 0;
  top: -7.6rem;
  width: 100%;
  max-width: 67.1rem;

  ${media.lessThan('large')`
    position: initial;
    margin: 0 auto;
    max-width: 100%;
  `}
`

export const ImagesGallery = styled.div`
  width: 100%;
  max-width: 1366px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 384px 384px;
  grid-template-areas: "one two three"
  "four four three";
  
  ${media.lessThan('medium')`
    grid-template-rows: 164px 164px;
  `}
`

export const ImageOne = styled.div`
  max-width: 45.6rem;
  max-height: 38.4rem;
  grid-area: one;
  
  .gatsby-image-wrapper {
    height: 100%;
  }
  
  ${media.lessThan('medium')`
    max-width: calc(100vw - 66.67%);
    max-height: 16.4rem;
  `}
`

export const ImageTwo = styled.div`
  max-width: 45.7rem;
  max-height: 38.4rem;
  grid-area: two;
  
  .gatsby-image-wrapper {
    height: 100%;
  }

  ${media.lessThan('medium')`
    max-width: calc(100vw - 66.67%);
    max-height: 16.4rem;
  `}
`

export const ImageThree = styled.div`
  min-height: 76.8rem;
  grid-area: three;
  
  .gatsby-image-wrapper {
    height: 100%;
  }

  ${media.lessThan('medium')`
    max-width: calc(100vw - 66.67%);
    min-height: 32.8rem;
  `}
`

export const ImageFour = styled.div`
  grid-area: four;
  max-height: 38.4rem;

  .gatsby-image-wrapper {
    height: 100%;
  }

  ${media.lessThan('medium')`
    max-width: calc(100vw - 33.33%);
  `}
`

export const BlueBackground = styled.div`
  width: 100%;
  height: 8rem;
  background-color: ${props => props.theme.colors.brand.primary[10]};
`