import React from 'react'
import CountUp from 'react-countup'
import PropTypes from 'prop-types'
import * as S from './styled'

const BrandNumbers = ({ field, stateNumber, stateTitle }) => {
  return (
    <S.BrandNumbersItems>

      <S.BrandNumbersContent>
        <S.BrandNumbersMain>
          <CountUp delay={2} end={stateNumber} />
          {field !== "countries" ? (
            <S.CountAuxiliaryText> +</S.CountAuxiliaryText>
          ) : (
            null
          )}
          <S.BrandNumbersTitle>
            {stateTitle}
          </S.BrandNumbersTitle>
        </S.BrandNumbersMain>
      </S.BrandNumbersContent>
    </S.BrandNumbersItems>
  )
}

BrandNumbers.propTypes = {
  stateNumber: PropTypes.string.isRequired,
  stateDescription: PropTypes.string.isRequired,
}

export default BrandNumbers
